import { createApp } from 'vue'
import Home from './Home.vue'
import About from '@/pages/about/About.vue'
import { createRouter, createWebHistory } from 'vue-router'
 
 
const routes = [
  { path: '/home', name: 'Home', component: Home },
  { path: '/about', name: 'About', component: About }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

createApp(Home).use(router).mount('#app')